var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-10"},[_c('v-toolbar',{staticClass:"mb-10",attrs:{"dense":"","flat":""}},[_c('div',{staticClass:"text-h4 primary--text"},[_vm._v("ใบเสร็จรับเงิน")]),_c('div',{staticStyle:{"width":"400px"}},[_c('v-text-field',{staticClass:"ml-10",attrs:{"prepend-inner-icon":"mdi-magnify","label":"ค้นหา เลขทะเบียน ชื่อลูกค้า เบอร์โทร","outlined":"","rounded":"","dense":"","single-line":"","hide-details":""},on:{"keyup":_vm.getData},model:{value:(_vm.filter.start_with),callback:function ($$v) {_vm.$set(_vm.filter, "start_with", $$v)},expression:"filter.start_with"}})],1),_c('v-spacer'),_c('div',{staticStyle:{"width":"200px"}},[_c('DatePicker',{attrs:{"label":"วันที่","value":_vm.filter.start_date,"hideDetails":"","outlined":"","rounded":"","dense":""},on:{"onChange":function (val) {
            _vm.filter.start_date = val;
            _vm.getData();
          }}})],1),_c('div',{staticStyle:{"width":"200px"}},[_c('DatePicker',{attrs:{"label":"ถึงวันที่","value":_vm.filter.end_date,"hideDetails":"","outlined":"","rounded":"","dense":""},on:{"onChange":function (val) {
            _vm.filter.end_date = val;
            _vm.getData();
          }}})],1),_c('v-spacer'),_c('div',{staticStyle:{"width":"200px"}},[_c('v-select',{attrs:{"label":"สถานะ","items":_vm.status,"item-value":"value","item-text":"text","outlined":"","rounded":"","dense":"","hide-details":""},on:{"change":_vm.getData},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}})],1),_c('v-btn',{staticClass:"ml-5",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.add()}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-plus")]),_vm._v(" เพิ่มรายการ")],1)],1),_c('v-data-table',{staticClass:"elevation-2 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.list,"loading":_vm.loading,"loading-text":_vm.$table_loading_text,"page":_vm.filter.page,"items-per-page":_vm.filter.item_per_page,"hide-default-footer":""},on:{"update:page":function($event){return _vm.$set(_vm.filter, "page", $event)},"click:row":_vm.edit},scopedSlots:_vm._u([{key:"item.date_of_issue",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.date_of_issue,_vm.$formatDate))+" ")]}},{key:"item.customer_phone_number",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.phoneNumberFormat(item.customer_phone_number)))])]}},{key:"item.net_price",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numeral")(item.net_price,'0,0.00'))+" ")]}},{key:"item.payment.type",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.payment.type == 1 ? 'โอนผ่าน QR Code' : '')+" "+_vm._s(item.payment.type == 2 ? 'บัตรเครดิต' : '')+" "+_vm._s(item.payment.type == 3 ? 'โอนผ่านเลขบัญชี' : '')+" ")]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('menu-ship-receipt',{attrs:{"small":"","id":item._id,"statusId":item.status,"docTypeId":_vm.docTypeId},on:{"success":function($event){return _vm.getData()}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"grey","small":"","outlined":"","rounded":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[[_c('v-list-item',{attrs:{"dense":"","link":""},on:{"click":function($event){return _vm.print(item)}}},[_c('v-list-item-title',[_vm._v("พิมพ์")])],1),_c('v-list-item',{attrs:{"dense":"","link":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-list-item-title',[_vm._v("แก้ไข")])],1),_c('v-list-item',{attrs:{"dense":"","link":""},on:{"click":function($event){return _vm.reset(item)}}},[_c('v-list-item-title',[_vm._v("ลบ")])],1)]],2)],1)]}},{key:"footer",fn:function(){return [_c('table-pagination-custom',{attrs:{"page":_vm.filter.page,"itemsPerPage":_vm.filter.item_per_page,"length":_vm.pageCount},on:{"change-page":_vm.onChangePage,"change-items-per-page":_vm.onChangeitemsPerPage}})]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }